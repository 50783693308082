.Container {
    padding-bottom: 20px;
    transition: transform 0.3s;
    position: fixed;
    left: 50%;
    bottom: 0;
    user-select: none;

    &--show {
        transform: translate(-50%, 0);
    }

    &--hide {
        transform: translate(-50%, 110%);
    }
}

.DefaultContent {
    padding: 5px 16px;
    border-radius: 4px;
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}