.ContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 35px;
  text-align: left;

  h4.Profession {
    font-size: 16px;
    color: var(--text-3);
    font-weight: 300;
    margin-bottom: 10px;
  }

  h1.FullName {
    font-size: 48px;
    line-height: 56px;
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--text-1);
  }

  p.Description {
    font-size: 14px;
    font-style: normal;
    line-height: 1.75em;
    color: var(--text-2);
    max-width: 500px;
    text-align: justify;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .EmailContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    align-items: center;
    font-size: 13px;
    color: var(--text-1);
    margin-top: 8px;

    .EmailIconContainer {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;

      .EmailIcon {
        width: 20px;
        height: 20px;

        path {
          stroke: var(--text-1) !important;
          fill: var(--text-1) !important;
        }
      }
    }
  }

  .ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;

    .DownloadButton {
      text-decoration: none;
      margin-right: 16px;
    }

    .SotialLinkContainer {
      width: 30px;
      height: 30px;
      margin-left: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;

      &:first-of-type {
        margin-left: 0px;
      }

      .Label {
        position: absolute;
        top: calc(100% + 10px);
        padding: 3px 8px;
        background-color: rgb(81, 81, 81);
        border-radius: 4px;
        color: white;
        font-size: 11px;
        transition: 0.2s;
        opacity: 0;
        transform: translateY(10px);
        pointer-events: none;
        text-align: center;
        white-space: nowrap;
      }

      &:hover .Label {
        opacity: 1;
        transform: translateY(0);
      }

      .SotialIcon {
        border-radius: 50%;
        overflow: hidden;
        width: 100%;
        height: 100%;

        path {
          fill: var(--text-3);
          transition: 0.3s;
          opacity: 1;
        }

        &:hover path {
          fill: var(--primary-hover);
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    margin-left: 0;
    margin-top: 35px;
    align-items: center;
    text-align: center;

    h1.FullName {
      font-size: 33px;
    }

    p.Description {
      text-align: center;
    }

    .ButtonsContainer {
      justify-content: center;
    }

    .ButtonsContainer .SotialLinkContainer .Label {
        display: none;
    }
  }
}
