.SectionTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;

  .SectionTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--text-1);
    font-size: 18px;

    h3 {
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .Seprator {
      width: 100%;
      height: 2px;
      background-color: rgb(223, 223, 223);
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &::after {
        content: "";
        position: relative;
        left: 0;
        height: 100%;
        background-color: var(--primary);
        width: 30px;
      }
    }
  }
}
