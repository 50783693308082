.Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .Message {
    font-size: 100px;
    color: var(--primary);
    margin-bottom: 20px;
    cursor: pointer;
    opacity: 0.8;
    user-select: none;
    transition: 0.3s;

    &:hover {
        color: var(--primary-hover);
        opacity: 1;
    }

    @media screen and (max-width: 850px) {
        cursor: unset;
        pointer-events: none;

        &:hover {
            color: var(--primary);
            opacity: 0.8;
        }
    }
  }

  .ButtonContainer {
    text-decoration: none;
    .Button {
      background-color: var(--background-color-1);
      font-weight: bold;
      &:hover {
        background-color: var(--primary);
      }
    }
  }
}
