.ImageContainer {
  align-self: stretch;
  display: flex;
  align-items: center;

  &--details {
    z-index: 1;
  }

  .ImageParent {
    width: 300px;
    height: 300px;
    background-color: var(--background-color-2);
    border-radius: 50%;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 25px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    }

    .Image {
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      max-width: 400px;
      max-height: 400px;
    }

    @keyframes LoadingImageProfile {
      0% {
        background-color: var(--primary);
        scale: 1;
      }
      50% {
        background-color: var(--primary-hover);
        scale: 1.05;
      }
      100% {
        background-color: var(--primary);
        scale: 1;
      }
    }

    .Loading {
      height: calc(100% - 50px);
      aspect-ratio: 1 / 1;
      max-width: 400px;
      max-height: 400px;
      position: absolute;
      border-radius: 50%;
      animation: LoadingImageProfile 5s linear infinite;
      opacity: 0;
      transition: 0.5s;
      pointer-events: none  ;

      &--show {
        opacity: 1;
      }
    }

    &--details {
      align-self: unset;
      position: fixed;
      transition: all 0.5s;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
      cursor: unset;

      .Close {
        width: 40px;
        height: 40px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: fixed;
        transition: 0.3s;
        top: 15px;
        right: 15px;
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          background-color: rgba(197, 197, 197, 0.446);
        }

        .Icon {
          width: 100%;
          height: 100%;

          path {
            fill: var(--text-1);
          }
        }


      }
    }
  }
  @media screen and (max-width: 820px) {
    align-self: center;

    .ImageParent .Image, .ImageParent .Loading {
      max-width: 300px;
      max-height: 300px;
    }
  }
}

.ToastContainer {
  padding: 5px 16px;
  border-radius: 4px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ToastIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: #ff4d4d;
    }
  }

  .ToastContent {
    color: white;
    font-weight: bold;
    font-size: 14px;
  }
}